import axios from 'axios'
// 某些內容需要authentication header才能取得
import authHeader from './auth-header'
import Config from '@/config/config'
const API_URL = Config.basicBackendBaseURL

class UserService {
  addAppRecordToBufferDB () {
    // console.log('Add app content into buffer db ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    return axios.get(API_URL + 'service/add-records', { headers: headerConfig })
  }

  getAuthRecordContent () {
    // console.log('Get auth content ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    return axios.get(API_URL + 'blockchain/all-blockchain-records-uuid', { headers: headerConfig })

    // const headerContent = { Accept: 'application/json' }
    // return axios.get(API_URL + 'service/all-records', { headers: headerContent })
  }

  checkAuthRecordContent () {
    // console.log('Get auth content ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    return axios.get(API_URL + 'blockchain/recheck-record-verified', { headers: headerConfig })

    // const headerContent = { Accept: 'application/json' }
    // return axios.get(API_URL + 'service/all-records', { headers: headerContent })
  }

  // Hyperledger Fabric network connection checking
  checkConnection () {
    // console.log('Check blockchain connection ...')
    const headerContent = { Accept: 'application/json' }
    return axios.get(API_URL + 'blockchain/connection', { headers: headerContent })
  }

  // App backend connection checking
  checkAppConnection () {
    // console.log('Check blockchain connection ...')
    const headerContent = { Accept: 'application/json' }
    return axios.get(API_URL + 'service/app-connection', { headers: headerContent })
  }

  switchErrorTag (recordId) {
    // console.log('Switch blockchain err tag ...')
    const dataForm = new FormData()
    dataForm.append('record_id', recordId)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.post(API_URL + 'blockchain/switch-record-err-tag', dataForm, { headers: headerConfig })
  }

  GenerateNewNode (product) {
    // console.log('Generate node ...')
    const dataForm = new FormData()
    dataForm.append('product', product)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.post(API_URL + 'graph/generate-nodes', dataForm, { headers: headerConfig })
  }

  GenerateNewGraph (product) {
    // console.log('Generate graph structure and style ...')
    const dataForm = new FormData()
    dataForm.append('product', product)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.post(API_URL + 'graph/generate-graph-structure-style', dataForm, { headers: headerConfig })
  }

  GetRecordFromApp () {
    // console.log('Get Records From App ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(API_URL + 'service/add-records', { headers: headerConfig })
  }

  SendBufferRecordToBlockchain () {
    // console.log('Verify Buffer Records To Blockchain ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(API_URL + 'blockchain/add-all-records', { headers: headerConfig })
  }

  RecheckRecordVerified () {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(API_URL + 'blockchain/recheck-record-verified', { headers: headerConfig })
  }
}
// 輸出的會是建立的UserService instance
export default new UserService()
